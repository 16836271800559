<template>
    <v-container
        v-resize="onResize"
        fluid
        class="overflow-y-hidden pa-0"
        ref="container"
        style="height:100%"
    >
        <v-row ref="followUpHeader" class="d-flex">
            <v-col class="ml-5 my-0 pa-0 d-flex align-center">
                <v-btn
                    rounded
                    depressed
                    color="primary"
                    class="white--text"
                    @click="openTaskFormDialog"
                    hide-details
                >
                    <v-icon left>mdi-plus</v-icon>
                    <span>New</span>
                </v-btn>
                <v-card-title style="max-width:220px">
                    <v-combobox
                        v-model="value"
                        :items="collaborators"
                        label="Projects"
                        flat
                        solo
                        multiple
                        text
                        hide-details
                    >
                        <template v-slot:selection="{ index }">
                            <div v-if="index === 0" class="mr-6">
                                <span>Users</span>
                            </div>
                            <v-badge
                                v-if="index === 0"
                                :content="value.length"
                                :value="collaborators"
                                color="primary"
                                overlap
                                class="mt-n2"
                            >
                            </v-badge>
                        </template>
                    </v-combobox>
                </v-card-title>
                <v-card-title style="max-width:220px">
                    <v-combobox
                        v-model="value"
                        :items="collaborators"
                        label="Quotes"
                        flat
                        solo
                        multiple
                        text
                        hide-details
                    >
                        <template v-slot:selection="{ index }">
                            <div v-if="index === 0" class="mr-6">
                                <span>Users</span>
                            </div>
                            <v-badge
                                v-if="index === 0"
                                :content="value.length"
                                :value="collaborators"
                                color="primary"
                                overlap
                                class="mt-n2"
                            >
                            </v-badge>
                        </template>
                    </v-combobox>
                </v-card-title>
            </v-col>
        </v-row>
        <v-progress-linear v-if="loading" color="primary" indeterminate />
        <v-divider class="mb-2"></v-divider>
        <v-card
            flat
            :class="{
                'overflow-x-auto': $vuetify.breakpoint.smAndDown,
                '': $vuetify.breakpoint.mdAndUp,
            }"
        >
            <div>
                <v-row class="flex-nowrap ma-0">
                    <v-col
                        class="pl-2 pr-0 py-0 ma-0"
                        cols="8"
                        xl="3"
                        lg="3"
                        md="5"
                    >
                        <v-card
                            style="background-color: #fafafa; border-radius: 10px"
                            flat
                            class="ma-0 pa-0"
                        >
                            <p
                                class="ma-0 pa-3 pb-1 d-flex font-weight-bold"
                                style="color: #EDC43B;"
                            >
                                ON HOLD
                            </p>
                            <v-row class="flex-nowrap ma-0 pa-0">
                                <draggable
                                    class="list-group overflow-y-auto px-2 py-0 ma-0"
                                    :style="
                                        `height:${this.height -
                                            62}px; width: 100%`
                                    "
                                    v-model="onHold"
                                    group="tasks"
                                    @change="onHoldLog"
                                >
                                    <div
                                        class="list-group-item py-0 mx-0 my-3 px-1"
                                        v-for="(task, index) in onHold"
                                        :key="index"
                                    >
                                        <v-sheet
                                            min-height="100"
                                            class="fill-height"
                                            color="transparent"
                                        >
                                            <v-lazy
                                                :v-model="task"
                                                :options="{
                                                    threshold: 0.5,
                                                }"
                                                class="fill-height"
                                            >
                                                <TaskCard
                                                    :task="task"
                                                    :users="users"
                                                    @openTaskDetails="
                                                        openTaskDetails
                                                    "
                                                    @enableLoadAnimation="
                                                        enableLoadAnimation
                                                    "
                                                />
                                            </v-lazy>
                                        </v-sheet>
                                    </div>
                                </draggable>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col class="px-2 py-0 ma-0" cols="8" xl="3" lg="3" md="5">
                        <v-card
                            style="background-color: #fafafa; border-radius: 10px"
                            flat
                            class="ma-0 pa-0"
                        >
                            <p
                                class="ma-0 pa-3 pb-1 d-flex font-weight-bold"
                                style="color: #3277D1;"
                            >
                                TO DO
                            </p>
                            <v-row class="flex-nowrap ma-0 pa-0">
                                <draggable
                                    class="list-group overflow-y-auto px-2 py-0 ma-0"
                                    :style="
                                        `height:${this.height -
                                            62}px; width: 100%`
                                    "
                                    v-model="toDo"
                                    group="tasks"
                                    @change="toDoLog"
                                >
                                    <div
                                        class="list-group-item py-0 mx-0 my-3 px-1"
                                        v-for="(task, index) in toDo"
                                        :key="index"
                                    >
                                        <v-sheet
                                            min-height="100"
                                            class="fill-height"
                                            color="transparent"
                                        >
                                            <v-lazy
                                                :v-model="task"
                                                :options="{
                                                    threshold: 0.5,
                                                }"
                                                class="fill-height"
                                            >
                                                <TaskCard
                                                    :task="task"
                                                    :users="users"
                                                    @openTaskDetails="
                                                        openTaskDetails
                                                    "
                                                    @enableLoadAnimation="
                                                        enableLoadAnimation
                                                    "
                                                />
                                            </v-lazy>
                                        </v-sheet>
                                    </div>
                                </draggable>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col
                        class="pr-2 pl-0 py-0 ma-0"
                        cols="8"
                        xl="3"
                        lg="3"
                        md="5"
                    >
                        <v-card
                            style="background-color: #fafafa; border-radius: 10px"
                            flat
                            class="ma-0 pa-0"
                        >
                            <p
                                class="ma-0 pa-3 pb-1 d-flex font-weight-bold"
                                style="color: #FF9800;"
                            >
                                IN PROGRESS
                            </p>
                            <v-row class="flex-nowrap ma-0 pa-0">
                                <draggable
                                    class="list-group overflow-y-auto px-2 py-0 ma-0"
                                    :style="
                                        `height:${this.height -
                                            62}px; width: 100%`
                                    "
                                    v-model="inProgress"
                                    group="tasks"
                                    @change="inProgressLog"
                                >
                                    <div
                                        class="list-group-item py-0 mx-0 my-3 px-1"
                                        v-for="(task, index) in inProgress"
                                        :key="index"
                                    >
                                        <v-sheet
                                            min-height="100"
                                            class="fill-height"
                                            color="transparent"
                                        >
                                            <v-lazy
                                                :v-model="task"
                                                :options="{
                                                    threshold: 0.5,
                                                }"
                                                class="fill-height"
                                            >
                                                <TaskCard
                                                    :task="task"
                                                    :users="users"
                                                    @openTaskDetails="
                                                        openTaskDetails
                                                    "
                                                    @enableLoadAnimation="
                                                        enableLoadAnimation
                                                    "
                                                />
                                            </v-lazy>
                                        </v-sheet>
                                    </div>
                                </draggable>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col
                        class="pr-2 pl-0 py-0 ma-0"
                        cols="8"
                        xl="3"
                        lg="3"
                        md="5"
                    >
                        <v-card
                            style="background-color: #fafafa; border-radius: 10px"
                            flat
                            class="ma-0 pa-0"
                        >
                            <p
                                class="ma-0 pa-3 pb-1 d-flex font-weight-bold"
                                style="color: #27BC4F;"
                            >
                                DONE
                            </p>
                            <v-row class="flex-nowrap ma-0 pa-0">
                                <draggable
                                    class="list-group overflow-y-auto px-2 py-0 ma-0"
                                    :style="
                                        `height:${this.height -
                                            62}px; width: 100%`
                                    "
                                    v-model="done"
                                    group="tasks"
                                    @change="doneLog"
                                >
                                    <div
                                        class="list-group-item py-0 mx-0 my-3 px-1"
                                        v-for="(task, index) in done"
                                        :key="index"
                                    >
                                        <v-sheet
                                            min-height="100"
                                            class="fill-height"
                                            color="transparent"
                                        >
                                            <v-lazy
                                                :v-model="task"
                                                :options="{
                                                    threshold: 0.5,
                                                }"
                                                class="fill-height"
                                            >
                                                <TaskCard
                                                    :task="task"
                                                    :users="users"
                                                    @openTaskDetails="
                                                        openTaskDetails
                                                    "
                                                    @enableLoadAnimation="
                                                        enableLoadAnimation
                                                    "
                                                    @openHoursDialog="
                                                        openHoursDialog
                                                    "
                                                />
                                            </v-lazy>
                                        </v-sheet>
                                    </div>
                                </draggable>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-card>
        <!-- task  -->
        <v-dialog
            :retain-focus="false"
            v-model="openTaskForm"
            persistent
            max-width="640px"
        >
            <TaskForm
                v-if="openTaskForm"
                :task="selectedTask"
                :originalTask="originalTask"
                :createForm="createForm"
                @closeDialog="closeTaskFormDialog"
                @replaceTask="replaceTask"
                @addTask="addTask"
            />
        </v-dialog>
        <!-- task details  -->
        <v-dialog
            :retain-focus="false"
            v-model="viewTaskDetails"
            persistent
            max-width="640px"
        >
            <TaskDetails
                v-if="viewTaskDetails"
                :task="selectedTask"
                :users="users"
                @openEditDialog="openEditDialog"
                @closeDialog="closeTaskDetails"
                @replaceTask="replaceTask"
            />
        </v-dialog>
        <!-- task  -->
        <v-dialog
            :retain-focus="false"
            v-model="openHoursForm"
            persistent
            max-width="340px"
        >
            <HoursForm
                v-if="openHoursForm"
                :taskHours="taskHours"
                @closeHoursDialog="closeHoursDialog"
                @updateHours="updateHours"
            />
        </v-dialog>
        <v-alert
            :value="activateAlert"
            style="position:fixed; bottom: 30px; right: 0;"
            color="blue"
            dismissible
            type="success"
            transition="scale-transition"
        >
            The task was successfully created.
        </v-alert>
    </v-container>
</template>

<script>
import API from '@/services/api'
import TaskCard from '@/components/Tasks/TaskCard'
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import draggable from 'vuedraggable'
import TaskForm from '@/components/Tasks/TaskForm.vue'
import TaskDetails from '@/components/Tasks/TaskDetails.vue'
import HoursForm from '@/components/Tasks/HoursForm.vue'
import { firestore } from '@/services/firebase'
import _ from 'lodash'

export default {
    name: 'MainBoard',
    components: {
        TaskCard,
        draggable,
        TaskForm,
        TaskDetails,
        HoursForm,
    },
    data() {
        return {
            loading: false,
            loadingError: false,
            errorMsg: null,
            error: null,
            dialogQuote: false,
            createDialog: false,
            value: null,
            onHold: [],
            toDo: [],
            inProgress: [],
            done: [],
            collaborators: [],
            folderLogo: 'company_logo',
            height: 0,
            heightPopUp: 0,
            openTaskForm: false,
            tasks: [],
            viewTaskDetails: false,
            selectedTask: {},
            users: [],
            createForm: false,
            user: JSON.parse(localStorage.getItem('userId')),
            companyId: JSON.parse(localStorage.getItem('company')),
            creatingFlag: true,
            status: [],
            openHoursForm: false,
            selectedTaskId: undefined,
            taskHours: undefined,
            originalTask: {},
            activateAlert: false,
            listener: null,
            resourceId: undefined,
        }
    },
    provide() {
        return {
            closeTaskDetails: this.closeTaskDetails,
        }
    },
    computed: {
        ...mapState(['notificationResource']),
    },
    watch: {
        async onHold() {
            this.status.push('onHold')
        },
        async toDo() {
            this.status.push('toDo')
        },
        async inProgress() {
            this.status.push('inProgress')
        },
        async done() {
            this.status.push('done')
        },
        notificationResource: function(resource) {
            if (resource) {
                this.resourceId = resource
                this.openResource()
                this.setNotificationResource(undefined)
            }
        },
    },
    methods: {
        ...mapActions(['setFromMainBoardAction']),
        ...mapMutations(['setErrorItems']),
        ...mapActions(['setNotificationResource']),
        ...mapGetters(['getNotificationResource']),
        async updateHours(hours) {
            try {
                const task = this.tasks.find(t => t.id == this.selectedTaskId)
                await API.updateTask(this.selectedTaskId, {
                    hours,
                    assignedTo: task.assignedTo,
                    notificationId: 'rCJFinVt7UiC6uTysz2i',
                })
                this.closeHoursDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        closeHoursDialog() {
            this.selectedTaskId = undefined
            this.taskHours = undefined
            this.openHoursForm = false
        },
        openHoursDialog(taskId) {
            this.selectedTaskId = taskId
            const task = this.tasks.find(t => t.id == this.selectedTaskId)
            this.taskHours = task.hours
            this.openHoursForm = true
        },
        replaceTask(task) {
            this.selectedTask = { ...this.selectedTask, ...task }
            let date = new Date(this.selectedTask.startDate)
            this.selectedTask.startDate = {
                seconds: date.getTime() / 1000,
            }
            date = new Date(this.selectedTask.expectedDate)
            this.selectedTask.expectedDate = {
                seconds: date.getTime() / 1000,
            }
        },
        async addTasks(added) {
            this.tasks = [...this.tasks, ...added]
            this.onHold = this.tasks
                .filter(task => task.status === 'onHold')
                .sort(function(a, b) {
                    return a.index - b.index
                })
            this.toDo = this.tasks
                .filter(task => task.status === 'toDo')
                .sort(function(a, b) {
                    return a.index - b.index
                })
            this.inProgress = this.tasks
                .filter(task => task.status === 'inProgress')
                .sort(function(a, b) {
                    return a.index - b.index
                })
            this.done = this.tasks
                .filter(task => task.status === 'done')
                .sort(function(a, b) {
                    return a.index - b.index
                })
            this.resourceId = this.$route.query.resourceId
            this.openResource()
            this.loading = false
        },
        async modifiedTasks(modified) {
            modified.forEach(task => {
                const index = this.tasks.findIndex(t => task.id == t.id)
                if (index >= 0) {
                    this.tasks[index] = task
                }
            })
            await this.addTasks([])
            this.loading = false
        },
        async removedTasks(removed) {
            removed.forEach(task => {
                const index = this.tasks.findIndex(t => task.id == t.id)
                if (index >= 0) {
                    this.tasks.splice(index, 1)
                }
            })
            await this.addTasks([])
            this.loading = false
        },
        addTask(task) {
            this.activateAlert = task && true
        },
        openEditDialog() {
            this.createForm = false
            this.openTaskForm = true
        },
        openTaskDetails(task) {
            this.selectedTask = _.cloneDeep(task)
            this.originalTask = _.cloneDeep(task)
            this.viewTaskDetails = true
        },
        closeTaskDetails() {
            this.selectedTask = {}
            this.viewTaskDetails = false
        },
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                followUpHeader: { clientHeight: headerHeight },
            } = this.$refs
            this.height = containerHeight - headerHeight + 19
            this.heightPopUp = window.innerHeight - 62
        },
        onHoldLog: async function(evt) {
            window.console.log(evt)
            if (evt.added) {
                const index = evt.added.newIndex
                let beforeIndex = 0
                let afterIndex = 0
                if (index > 0) {
                    beforeIndex = this.onHold[index - 1].index
                }
                if (index < this.onHold.length - 1) {
                    afterIndex = this.onHold[index + 1].index
                } else if (index == this.onHold.length - 1) {
                    afterIndex = beforeIndex + 1
                }
                const task = evt.added.element
                task.index = (beforeIndex + afterIndex) / 2
                await API.updateTask(task.id, {
                    status: 'onHold',
                    index: task.index,
                    assignedTo: task.assignedTo,
                    notificationId: 'rCJFinVt7UiC6uTysz2i',
                })
            } else if (evt.moved) {
                const index = evt.moved.newIndex
                let task = evt.moved.element
                let beforeIndex = 0
                let afterIndex = 0
                if (index > 0) {
                    beforeIndex = this.onHold[index - 1].index
                }

                if (index < this.onHold.length - 1) {
                    afterIndex = this.onHold[index + 1].index
                } else if (index == this.onHold.length - 1) {
                    afterIndex = beforeIndex + 1
                }

                const newIndex = (beforeIndex + afterIndex) / 2
                await API.updateTask(task.id, {
                    index: newIndex,
                    assignedTo: task.assignedTo,
                    notificationId: 'rCJFinVt7UiC6uTysz2i',
                })
            }
        },
        toDoLog: async function(evt) {
            window.console.log(evt)
            if (evt.added) {
                const index = evt.added.newIndex
                let beforeIndex = 0
                let afterIndex = 0
                if (index > 0) {
                    beforeIndex = this.toDo[index - 1].index
                }
                if (index < this.toDo.length - 1) {
                    afterIndex = this.toDo[index + 1].index
                } else if (index == this.toDo.length - 1) {
                    afterIndex = beforeIndex + 1
                }
                const task = evt.added.element
                task.index = (beforeIndex + afterIndex) / 2
                await API.updateTask(task.id, {
                    status: 'toDo',
                    index: task.index,
                    assignedTo: task.assignedTo,
                    notificationId: 'rCJFinVt7UiC6uTysz2i',
                })
            } else if (evt.moved) {
                const index = evt.moved.newIndex
                let task = evt.moved.element
                let beforeIndex = 0
                let afterIndex = 0
                if (index > 0) {
                    beforeIndex = this.toDo[index - 1].index
                }

                if (index < this.toDo.length - 1) {
                    afterIndex = this.toDo[index + 1].index
                } else if (index == this.toDo.length - 1) {
                    afterIndex = beforeIndex + 1
                }

                const newIndex = (beforeIndex + afterIndex) / 2
                await API.updateTask(task.id, {
                    index: newIndex,
                    assignedTo: task.assignedTo,
                    notificationId: 'rCJFinVt7UiC6uTysz2i',
                })
            }
        },
        inProgressLog: async function(evt) {
            window.console.log(evt)
            if (evt.added) {
                const index = evt.added.newIndex
                let beforeIndex = 0
                let afterIndex = 0
                if (index > 0) {
                    beforeIndex = this.inProgress[index - 1].index
                }
                if (index < this.inProgress.length - 1) {
                    afterIndex = this.inProgress[index + 1].index
                } else if (index == this.inProgress.length - 1) {
                    afterIndex = beforeIndex + 1
                }
                const task = evt.added.element
                task.index = (beforeIndex + afterIndex) / 2
                await API.updateTask(task.id, {
                    status: 'inProgress',
                    index: task.index,
                    assignedTo: task.assignedTo,
                    notificationId: 'rCJFinVt7UiC6uTysz2i',
                })
            } else if (evt.moved) {
                const index = evt.moved.newIndex
                let task = evt.moved.element
                let beforeIndex = 0
                let afterIndex = 0
                if (index > 0) {
                    beforeIndex = this.inProgress[index - 1].index
                }

                if (index < this.inProgress.length - 1) {
                    afterIndex = this.inProgress[index + 1].index
                } else if (index == this.inProgress.length - 1) {
                    afterIndex = beforeIndex + 1
                }

                const newIndex = (beforeIndex + afterIndex) / 2
                await API.updateTask(task.id, {
                    index: newIndex,
                    assignedTo: task.assignedTo,
                    notificationId: 'rCJFinVt7UiC6uTysz2i',
                })
            }
        },
        doneLog: async function(evt) {
            window.console.log(evt)
            if (evt.added) {
                const index = evt.added.newIndex
                let beforeIndex = 0
                let afterIndex = 0
                if (index > 0) {
                    beforeIndex = this.done[index - 1].index
                }
                if (index < this.done.length - 1) {
                    afterIndex = this.done[index + 1].index
                } else if (index == this.done.length - 1) {
                    afterIndex = beforeIndex + 1
                }
                const task = evt.added.element
                task.index = (beforeIndex + afterIndex) / 2
                await API.updateTask(task.id, {
                    status: 'done',
                    index: task.index,
                    assignedTo: task.assignedTo,
                    notificationId: 'B4Dv8NHXHbsidvLr4ful',
                })
                this.openHoursDialog(task.id)
            } else if (evt.moved) {
                const index = evt.moved.newIndex
                let task = evt.moved.element
                let beforeIndex = 0
                let afterIndex = 0
                if (index > 0) {
                    beforeIndex = this.done[index - 1].index
                }

                if (index < this.done.length - 1) {
                    afterIndex = this.done[index + 1].index
                } else if (index == this.done.length - 1) {
                    afterIndex = beforeIndex + 1
                }

                const newIndex = (beforeIndex + afterIndex) / 2
                await API.updateTask(task.id, {
                    index: newIndex,
                    assignedTo: task.assignedTo,
                    notificationId: 'B4Dv8NHXHbsidvLr4ful',
                })
            }
        },
        openTaskFormDialog: function() {
            this.createForm = true
            this.openTaskForm = true
            this.selectedTask = {}
        },
        closeTaskFormDialog: function() {
            this.createForm = false
            this.openTaskForm = false
        },
        enableLoadAnimation(state) {
            this.loading = state
        },
        openResource() {
            if (this.resourceId) {
                const resource = this.tasks.find(t => t.id == this.resourceId)
                if (resource) {
                    this.resourceId = undefined
                    this.openTaskDetails(resource)
                }
            }
        },
    },
    async created() {
        try {
            this.loading = true
            const tasksQuery = firestore
                .collection('users')
                .doc(this.user)
                .collection('tasks')
                .where('archive', '==', false)
                .where('companyId', '==', this.companyId)
            this.listener = await tasksQuery.onSnapshot(async docSnapshot => {
                let added = []
                let modified = []
                let removed = []
                docSnapshot.docChanges().forEach(async change => {
                    const task = Object.assign(change.doc.data(), {
                        id: change.doc.id,
                    })
                    if (change.type === 'added') {
                        this.loading = true
                        if (!task.archive) {
                            added.push(task)
                        }
                    }
                    if (change.type === 'modified') {
                        this.loading = true
                        if (!task.archive) {
                            modified.push(task)
                        }
                    }
                    if (change.type === 'removed') {
                        this.loading = true
                        removed.push(task)
                    }
                })
                if (added.length > 0) {
                    await this.addTasks(added)
                }
                if (modified.length > 0) {
                    await this.modifiedTasks(modified)
                }
                if (removed.length > 0) {
                    await this.removedTasks(removed)
                }
            })
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    async mounted() {
        try {
            this.onResize()
            // get users
            const {
                data: { users },
            } = await API.getUsers()
            this.users = users
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        }
    },
    beforeDestroy() {
        this.listener()
        this.listener = null
    },
}
</script>

<style scoped>
.borderRounded {
    border-radius: 10px;
}
.banner >>> .v-banner__wrapper {
    border-bottom: 0px !important;
    padding-right: 0px !important;
    padding-left: 0px !important;
}
</style>
